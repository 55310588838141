*{
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  
}

body{
  
  
}


.navbar-light .navbar-toggler-icon {
  background-image: url(../src//images/menuicon.png) !important ;
  width: 50px !important;
}

.navbar-light .navbar-toggler {
  color: rgba(181, 255, 162, 0.55) !important;
  border-color: rgba(125, 255, 113, 0.308) !important;
}

.Hero_Home{
  background-image: url(../src/images/foodcrisi.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;


}

@media screen and (min-width:1000px) {
  .Hero_Home{
    background-image: url(../src/images/foodcrisi.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
  
  
  }
}

.Hero_Text{
  font-size: 20px;
}

@media screen and (min-width:1000px) {
  .Hero_Text{
    font-size: 24px;
  }
}

/* general styling */

.Tech_p{
  font-size: 16px;
}


@media screen and (min-width:1000px) {
  .Tech_p{
    font-size: 19px;
  }
}
.footer_text{
  font-size: 10px;
  font-weight: 400;
  color: #000;
}
@media screen and (min-width:1000px) {
  .footer_text{
    font-size: 12px;
    font-weight: 400;
    color: #000;  
  }
}


.Hero_link{
  background-image: url(../src/images/linkbg.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;

}

@media screen and (min-width:1000px) {
  .Hero_link{
    background-image: url(../src/images/linkbg.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 95vh;
  
  }
}

.sol_head{
  color: #08843A;
  font-size: 17px;
  font-weight: 800;
  text-transform: uppercase;
}

@media screen and (min-width:1000px) {
  .sol_head{
    color: #08843A;
    font-size: 20px;
    font-weight: 800;
  }
}
.sol_para{
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}
@media screen and (min-width:1000px) {
  .sol_para{
    color: #000000;
    font-size: 16px;
    font-weight: 400;
  }
}



.image_right{
  position: relative;
  left: .1px;
}




@media screen and (min-width:1000px) {
  .image_right{
    position: relative;
    left: 80px;
  }
}

.text_left{
position: relative;
right: 1px;

}



@media screen and (min-width:1000px) {
  .text_left{
    position: relative;
    right: 80px;
  }
}





.Hero_Tech{
  background-image: url(../src/images/techbg.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
 

}

@media screen and (min-width:1000px) {
  .Hero_Tech{
    background-image: url(../src/images/techbg.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
  
  
  }
}


.Hero_Tech_Text{
  font-size: 40px;
  font-weight: 600;
  
}



@media screen and (min-width:1200px) {
  .Hero_Tech_Text{
    font-size: 50px;
    font-weight: 600;
   
  }
}






.structura_bg{
  background-image: url(../src/images/st_bgs.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;

}

@media screen and (min-width:1000px) {
  .structura_bg{
    background-image: url(../src/images/st_bgs.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
  
  }
}



.partner_alignment_text{
 position: relative;
 top: 1px;
}


@media screen and (min-width:1200px) {
  .partner_alignment_text{
    position: relative;
    top: 100px;
  }
}

.Struc_align{
  position: relative;
  top: 5px;
}




@media screen and (min-width:1000px) {

  .Struc_align{
    position: relative;
    top: 150px;
  }
}

         
.btn-grad {
  background-image: linear-gradient(to right, #B1D63C  11%,  #69C849 50%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 30px;
  display: block;
border: none;
display: inline-block;


}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}




.tec_card{
  background: linear-gradient(180deg, #E3EAEC 0%, #FEFFFF 100%);

}

.card {
  position: relative;
justify-content: center !important;
align-items: center !important; 
text-align: center !important; 
  display: flex;
  flex-direction: column;
  width: 340px !important;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px !important;
  border-radius: 0.75rem !important;
}





.Hero_Oferta{
  background-image: url(../src/images/ofertabg.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
 

}

@media screen and (min-width:1000px) {
  .Hero_Oferta{
    background-image: url(../src/images/ofertabg.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
  
  
  }
}





.structura_bg_oferta{
  background-image: url(../src/images/strucofertabgg.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;

}

@media screen and (min-width:1000px) {
  .structura_bg_oferta{
    background-image: url(../src/images/strucofertabgg.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
  
  }
}






.home_chain{
 width: 130px;

}

@media screen and (min-width:1000px) {
  .home_chain{
  
    width: 185px;
  }
}

.quadd{
  position: relative;
  top:0.2px;
}



@media screen and (min-width:1000px) {
  .quadd{
position: relative;
top:0.3px;
  }
  
}

.img{


  animation: 3s infinite alternate floating;
}
.shadow{
  height:2px;
  display:block;
  margin:15px auto;
  animation: 3s infinite alternate shadow;
}

@keyframes floating {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}
@keyframes shadow {
	0% {
    width:180px;
		box-shadow:0px 10px 8px rgba(0,0,0,.8);
	}
	50% {
    width:150px;
		box-shadow:0px 10px 8px rgba(0,0,0,.5);
	}
	100% {
    width:180px;
		box-shadow:0px 10px 8px rgba(0,0,0,.8);
	}
}


body::-webkit-scrollbar {
  width: 0.8em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 255, 55, 0.3);
}

body::-webkit-scrollbar-thumb {
background-color: rgb(231, 253, 151);
outline: 1px solid rgb(158, 171, 184);
}



.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {

  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;

  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 10px !important;
}




















.quadschaintext{
 position: relative;
 left: 55px; 
}


@media screen and (min-width:1000px) {
  .quadschaintext{
    position: relative;
    left: 95px; 
  }
}

.quadschainimage{
  position: relative;
  left: 25px; 
}



@media screen and (min-width:1000px) {
  .quadschainimage{
    position: relative;
    left: 35px; 
  }
}



.foodchaintext{
  position: relative;
    right: 45px; 
}


@media screen and (min-width:1000px) {
  .foodchaintext{
    position: relative;
    right: 65px; 
  }
}



.foodchainimage{
  position: relative;
  right: 35px; 
}



@media screen and (min-width:1000px) {
  .foodchainimage{
    position: relative;
    right: 40px; 
  }
}